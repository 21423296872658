import React from "react";

const Stats = () => {
  return (
    <section id="stats" className="stats section">
      <div className="container aos-init aos-animate" data-aos="fade-up" data-aos-delay={100}>
        <div className="row gy-4">
          <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
            <i className="bi bi-emoji-smile" />
            <div className="stats-item">
              <span
                data-purecounter-start={0}
                data-purecounter-end={7}
                data-purecounter-duration={1}
                className="purecounter"
              >7</span>
              <p>East Africa Partner States</p>
            </div>
          </div>
          {/* End Stats Item */}
          <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
            <i className="bi bi-journal-richtext" />
            <div className="stats-item">
              <span
                data-purecounter-start={0}
                data-purecounter-end={114}
                data-purecounter-duration={1}
                className="purecounter"
              >114</span>
              <p>Participants</p>
            </div>
          </div>
          {/* End Stats Item */}
          <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
            <i className="bi bi-headset" />
            <div className="stats-item">
              <span
                data-purecounter-start={0}
                data-purecounter-end={10}
                data-purecounter-duration={1}
                className="purecounter"
              >10</span>
              <p>Institutions per State</p>
            </div>
          </div>
          {/* End Stats Item */}
          <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
            <i className="bi bi-people" />
            <div className="stats-item">
              <span
                data-purecounter-start={0}
                data-purecounter-end={9574}
                data-purecounter-duration={1}
                className="purecounter"
              >9574</span>
              <p>Hours of Mentorship &amp; Engagement Planned</p>
            </div>
          </div>
          {/* End Stats Item */}
        </div>
      </div>
    </section>
  );
};

export default Stats;
