import React from "react";

const HeroSection = () => {
  return (
    <section  className="hero section">
      <img src="assets/img/Picture1.png" alt="" data-aos="fade-in"  className="aos-init aos-animate"/>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2 data-aos="fade-up" data-aos-delay={100} className="aos-init aos-animate">
              ULead
            </h2>
            <p data-aos="fade-up" data-aos-delay={200} className="aos-init aos-animate">
              Incubation Leadership and Innovation Management Programme for
              Universities in EAC
            </p>
            <div
              className="d-flex mt-4 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={300}
            >
              <a href="#about" className="btn-get-started">
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
