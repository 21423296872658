import React from "react";

const Title = () => {
  return (
    <div className="container section-title aos-init aos-animate" data-aos="fade-up">
      <h2
        style={{
          color: "black",
          textAlign: "center",
          fontSize: "2.5rem",
          marginBottom: 40,
        }}
      >
        Timeline of Activities
      </h2>
    </div>
  );
};

export default Title;
