import React from "react";

const About = () => {
  return (
    <section id="about" className="about section section-bg dark-background">
      <div className="container position-relative">
        <div className="row gy-5">
          <div
           className="content col-xl-5 d-flex flex-column aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay={100}
          >
            <h3>INTRODUCTION </h3>
            <p>
            Incubation Leadership and Innovation Management Program for
Universities in EAC” is part of the East African Community (EAC) project,
Digital Skills for an Innovative East African Industry (dSkills@EA)
implemented by the Deutsche Gesellschaft für Internationale
Zusammenarbeit (GIZ) and the Inter University Council for East Africa
(IUCEA) across all EAC Partner States. The project focuses on
strengthening academia-industry collaboration in digital skills training,
technology and innovation transfer to improve the uptake of digital skills and
innovations by the industry in the region.
The Incubation Leadership and Innovation Management
Program is a transformative initiative that underscores the critical role of
universities in spearheading digital transformation and capacity building of
entrepreneurial talent across the Region.
This program is meticulously designed to empower and elevate the
capabilities capacities of universities and their staff responsible research
management, technology transfer, incubation and acceleration of ideas, to
effectively support ideation, prototyping and commercialization of
innovations to market,
across the seven EAC Partner States,
including; Burundi, DR Congo, Kenya, Rwanda, South Sudan, Tanzania,
and Uganda.

            </p>
            <a
              href="/"
              className="about-btn align-self-center align-self-xl-start"
            >
              <span>About us</span> <i className="bi bi-chevron-right" />
            </a>
          </div>
          <div className="col-xl-7 aos-init aos-animate" data-aos="fade-up" data-aos-delay={200}>
            <div className="row gy-4">
              <div className="col-md-6 icon-box position-relative">
                <i className="bi bi-briefcase" />
                <p>
                Recognizing the pivotal position of universities as catalysts for innovation
and development, the program offers a comprehensive curriculum that
equips participants with essential skills in leadership of incubation programs
and innovation management. Through a blend of interactive workshops,
personalized consultations, immersive networking opportunities, and
practical learning co-creation and learning experiences, participants will gain
the knowledge and tools needed to foster innovation within their institutions
and beyond.
The Incubation Leadership and Innovation Management Program for
Universities in EAC is being implemented under the dSkills@EA project
with the support of the Buni Innovation Hub and SA Innovation Summit, in

a consortium of Entrepreneur Support Organizations (ESOs) that includes:
E4Impact Foundation, Ennovate Ventures, Aclis, and Koneta Hub, on behalf
of GIZ and IUCEA.
                </p>
              </div>
              {/* Icon-Box */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
