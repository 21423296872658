import React from "react";

const Responsibilities = () => {
  return (
    <section
      id="roles-responsibilities"
      style={{ padding: "60px 0", background: "#f0f2f5" }}
    >
      {/* Section Title */}
      <div
        className="container aos-init aos-animate"
        style={{
          width: "100%",
          maxWidth: 1200,
          margin: "0 auto",
          padding: "0 15px",
          textAlign: "center",
          marginBottom: 40,
        }}
        data-aos="fade-up"
      >
        <h2 style={{ color: "#00008B", fontWeight: "bold", fontSize: 32 }}>
          PROGRAM BENEFIT
        </h2>
      </div>
      {/* End Section Title */}
      <div
        className="container aos-init aos-animate"
        style={{
          width: "100%",
          maxWidth: 1200,
          margin: "0 auto",
          padding: "0 15px",
        }}
        data-aos="fade-up"
        data-aos-delay={100}
      >
        <div
          className="row"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 15,
            justifyContent: "center",
          }}
        >
          <div
            className="col-lg-4 col-md-6 mb-4"
            style={{ flex: "0 0 30%", maxWidth: "30%", marginBottom: 20 }}
          >
            <div
              className="card"
              style={{
                backgroundColor: "#fff",
                border: "none",
                borderRadius: 8,
                padding: 20,
                textAlign: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease-in-out",
              }}
            >
              <div className="card-body">
                <i
                  className="fas fa-lightbulb"
                  style={{ fontSize: 40, color: "#00008B", marginBottom: 15 }}
                />
                <h5
                  className="card-title"
                  style={{
                    color: "#00008B",
                    fontWeight: "bold",
                    fontSize: 18,
                    marginBottom: 10,
                  }}
                >
                  Improved Incubation Centre Management
                </h5>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 mb-4"
            style={{ flex: "0 0 30%", maxWidth: "30%", marginBottom: 20 }}
          >
            <div
              className="card"
              style={{
                backgroundColor: "#fff",
                border: "none",
                borderRadius: 8,
                padding: 20,
                textAlign: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease-in-out",
              }}
            >
              <div className="card-body">
                <i
                  className="fas fa-graduation-cap"
                  style={{ fontSize: 40, color: "#00008B", marginBottom: 15 }}
                />
                <h5
                  className="card-title"
                  style={{
                    color: "#00008B",
                    fontWeight: "bold",
                    fontSize: 18,
                    marginBottom: 10,
                  }}
                >
                  {" "}
                  Increased commercialization of intellectual property in
                  Universities
                </h5>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 mb-4"
            style={{ flex: "0 0 30%", maxWidth: "30%", marginBottom: 20 }}
          >
            <div
              className="card"
              style={{
                backgroundColor: "#fff",
                border: "none",
                borderRadius: 8,
                padding: 20,
                textAlign: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "all 0.3s ease-in-out",
              }}
            >
              <div className="card-body">
                <i
                  className="fas fa-users"
                  style={{ fontSize: 40, color: "#00008B", marginBottom: 15 }}
                />
                <h5
                  className="card-title"
                  style={{
                    color: "#00008B",
                    fontWeight: "bold",
                    fontSize: 18,
                    marginBottom: 10,
                  }}
                >
                  {" "}
                  Being part of a community of professional on innovation
                  management{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Responsibilities;
