import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
const Client = () => {
  const swiperConfig = {
    loop: true,
    speed: 600,
    autoplay: {
      delay: 5000,
    },
    slidesPerView: "auto",
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      480: {
        slidesPerView: 3,
        spaceBetween: 60,
      },
      640: {
        slidesPerView: 4,
        spaceBetween: 80,
      },
      992: {
        slidesPerView: 6,
        spaceBetween: 120,
      },
    },
  };

  return (
    <section id="clients" class="clients section">
      <div
        className="container aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <Swiper {...swiperConfig}>
          <SwiperSlide>
            <img
              src="assets/img/clients/Picture6.png"
              className="img-fluid"
              alt="Client 6"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="assets/img/clients/Picture2.png"
              className="img-fluid"
              alt="Client 2"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="assets/img/clients/Picture3.png"
              className="img-fluid"
              alt="Client 3"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="assets/img/clients/logo.png"
              className="img-fluid"
              alt="Client Logo"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="assets/img/clients/kumi10.png"
              className="img-fluid"
              alt="Client Logo"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="assets/img/clients/kumi11.png"
              className="img-fluid"
              alt="Client Logo"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src="assets/img/clients/Picture5.png"
              className="img-fluid"
              alt="Client 5"
            />
          </SwiperSlide>
        </Swiper>
        <div className="swiper-pagination"></div>
      </div>
    </section>
  );
};

export default Client;
