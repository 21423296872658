import React from 'react'
import HeroSection from '../components/HeroSection'
import Client from '../components/Client'
import About from '../components/About'
import Stats from '../components/Stats'
import Tabs from '../components/Tabs'
import Title from '../components/Title'
import TimeLine from "../components/TimeLine"
import Responsibilities from '../components/Responsibilities'
import Pricing from '../components/Pricing'
import Faqs from '../components/Faqs'
import CallForApplication from '../components/CallForApplication'
const Home = () => {
  return (
    <main className="main">
      <HeroSection/>
      <Client/>
      <About/>
      <Stats/>
      <Tabs/>
      <Title/>
      <TimeLine/>
      <Responsibilities/>
      <Pricing/>
      <Faqs/>
      <CallForApplication/>
    </main>
  )
}

export default Home
